// Nodes modules
import './modules/@tc';
import 'classlist-polyfill';
import 'object-fit-polyfill';
//import animateScrollTo from 'animated-scroll-to';
import * as Stickyfill from 'stickyfilljs';
import barba from '@barba/core';

//import {TweenMax, Power2, gsap, ScrollToPlugin, ScrollTrigger, CSSRulePlugin, TimelineMax} from "gsap/all";
//gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSRulePlugin);

// Swiper
import Swiper, {Pagination, FreeMode } from 'swiper';
Swiper.use([Pagination, FreeMode]);

window.addEventListener('load', function(){
	// Menu Open - Open main nav
    var body = document.body;
    var burgerBtn = document.getElementById("burger-btn");

    function toggleMainNav() {
        body.classList.toggle("menu-open");
    } 
    
    if (typeof(burgerBtn) != 'undefined' && burgerBtn != null) {
        burgerBtn.onclick = toggleMainNav;
    }

    /* Responsive test function */
    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
        var mql = window.matchMedia(mediaQuery);
        mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
        layoutChangedCallback(mql.matches);
    } 

    /* Project teaser - Appear animation */  	
    const teaserAppear = () => {        
        gsap.utils.toArray('.project-teaser').forEach((projectTeaser, index) => {
            const teaserBigTitle = projectTeaser.querySelector('.project-teaser-big-title');
            //const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
			const [x, xEnd] = index % 2 ? ["-50%", "-65"] : ["-50%", "35"];
            gsap.fromTo(teaserBigTitle, {x: x}, {
                xPercent: xEnd,
                scrollTrigger: { 
                trigger: projectTeaser, 
                scrub: 0.5,
                ease: "power2.easeInOut"
                }
            });

			const teaserImgLink = projectTeaser.querySelector('.overlay-link-img');
            const teaserImgContainer = projectTeaser.querySelector('.project-teaser-img');
            const teaserImg = projectTeaser.querySelector('.project-teaser-img img');
            const teaserTitle = projectTeaser.querySelector('.project-teaser-title');
            const teaserDesc = projectTeaser.querySelector('.project-teaser-desc');
            const teaserBtn = projectTeaser.querySelector('.inline-btn');

			function teaserImgStart() {
				teaserImgLink.style.pointerEvents = "none";
			}

			function teaserImgComplete() {
				teaserImgLink.style.pointerEvents = "auto";
			}

            let teaserTimeline = gsap.timeline({
              scrollTrigger:{
                trigger: projectTeaser,
                start: "top 85%",
                end: "top 0%",
                //markers: true
              }
            });

			installMediaQueryWatcher("(min-width: 576px)", function(matches) {
				if (matches) {
					teaserTimeline.to(teaserImg, {
						scale:"1",
						duration: 0.25,
						opacity: 1,
						ease: 'power4.easeIn',
						onStart: teaserImgStart,
					});
				} else {
					teaserTimeline.to(teaserImg, {
						scale:"1",
						duration: 0.3,
						opacity: 1,
						ease: 'power4.easeIn',
						onStart: teaserImgStart,
						onComplete: teaserImgComplete,
					});
				}
			});
            
			installMediaQueryWatcher("(min-width: 576px)", function(matches) {
				if (matches) {
					teaserTimeline.to(teaserImgContainer, {
						clipPath: 'inset(0px 0px 0% 0px)',
						duration: 0.35,
						ease: 'power4.easeIn',
						onComplete: teaserImgComplete,
					});
				}
			});
				
			teaserTimeline.to(teaserTitle, {
				y: 0,
				//rotate: 0,
				duration: .5,
				opacity: 1,
			}, "-=0.1");

			teaserTimeline.to(teaserDesc, {
				y: 0,
				//rotate: 0,
				duration: .5,
				opacity: 1,
			}, "-=0.25");

			installMediaQueryWatcher("(min-width: 576px)", function(matches) {
				if (matches) {
					teaserTimeline.to(teaserBtn, {
						y: 0,
						//rotate: 0,
						duration: .5,
						opacity: 1,
					}, "-=0.25");
				}
			});	
        });
    }

    /* Main intro - Appear animation */
    const titleAppear = () => {   
		let titleTimeline = gsap.timeline({});

		titleTimeline.to('.main-header', {
        	//y: 0,
        	duration: 0.25,
        	opacity: 1,
      	});

      	titleTimeline.to('#main-title span span span', {
        	y: 0,
        	rotate: 0,
        	duration: .5,
        	opacity: 1,
        	stagger: 0.01,
      	});

		if (document.querySelector(".jumbotron p")) {
			titleTimeline.to('.jumbotron p', {
				y: 0,
				duration: .5,
				opacity: 1,
			}, "-=0.5");
		}

		installMediaQueryWatcher("(min-width: 576px)", function(matches) {
			if (matches) {
				gsap.fromTo("html", {
					"--opacityJumbotronAfter": 0,  
					}, 
					{
					"--opacityJumbotronAfter": 1,
					duration: 0.25,
				}, "-=0.2");
			}
		});

		if (document.querySelector(".basic-page-content")) {	
			titleTimeline.to('.basic-page-content', {
				opacity: 1, 
				duration: 0.25,
			}, "-=0.3");
		}

		if (document.querySelector(".projects-view-container")) {	
			titleTimeline.to('.projects-view-container', {
				opacity: 1, 
				duration: 0.25,
			}, "-=0.3");
		}

		if (document.querySelector(".project-intro-subtitle")) {
			installMediaQueryWatcher("(min-width: 576px)", function(matches) {
				if (matches) {
					titleTimeline.to('.project-intro-solutions', {
						borderTopWidth: 2,
						duration: .5,
					}, "-=0.25");
				}
			});

			titleTimeline.to('.project-intro-subtitle', {
				y: 0,
				duration: .5,
				opacity: 1,
			}, "-=0.25");

			titleTimeline.to('.project-intro-solutions p', {
				y: 0,
				duration: .5,
				opacity: 1,
			}, "-=0.25");

			const solutionListItem = document.querySelectorAll('.project-intro-solutions li');
			titleTimeline.to(solutionListItem, {
				y: 0,
				duration: .5,
				opacity: 1,
				stagger: 0.1
			}, "-=.25"); 

			installMediaQueryWatcher("(min-width: 576px)", function(matches) {
				if (matches) {
					titleTimeline.to('.project-main-img picture', {
						"clip-path": "inset(0px 0px 0% 0px)",
						duration: 0.75,
						ease: 'power4.easeIn',
					}, "-=0.5");

					titleTimeline.to('.project-main-img img', {
						scale:"1",
						duration: 0.75,
						ease: 'power4.easeIn',
					}, "-=0.75");
				} else {
					titleTimeline.to('.project-main-img picture', {
						"clip-path": "inset(0px 0px 0% 0px)",
						duration: 0.5,
						ease: 'power4.easeIn',
					}, "-=0.75");

					titleTimeline.to('.project-main-img img', {
						scale:"1",
						duration: 0.5,
						ease: 'power4.easeIn',
					}, "-=0.95");
				}
			});
		}
    }

    /* Service - Appear animation */	
    const serviceAppear = () => {
      gsap.utils.toArray('.content-service-block').forEach((serviceContainer, index) => {
			const serviceNumber = serviceContainer.querySelector('.service-title .prefix');
			const serviceTitle = serviceContainer.querySelector('.service-title h2');
			const serviceDesc = serviceContainer.querySelector('.service-desc');
			const serviceList = serviceContainer.querySelector('.service-list');
			const serviceListItem = serviceList.querySelectorAll('li');
		
			let serviceTimeline = gsap.timeline({
				scrollTrigger:{
					trigger: serviceContainer,
					start: "top 85%",
					end: "top 0%",
					//markers: true
				}
			});

			serviceTimeline.fromTo('html', {
				"--opacityServiceBorder": 0,  
				}, 
				{
				"--opacityServiceBorder": 1,
				duration: 0.3,
			});				

			installMediaQueryWatcher("(min-width: 576px)", function(matches) {
				if (matches) {
					serviceTimeline.to(serviceNumber, {
						y: 0,
						duration: .35,
						opacity: 1,
					});
				}
			});
				 
			serviceTimeline.to(serviceTitle, {
				y: 0,
				duration: .35,
				opacity: 1,
			}, "-=.25");

			serviceTimeline.to(serviceDesc, {
				y: 0,
				duration: .35,
				opacity: 1,
			}, "-=.25");

			serviceTimeline.to(serviceListItem, {
				y: 0,
				duration: .35,
				opacity: 1,
				stagger: 0.1
			}, "-=.25");
      });
    }

    teaserAppear();
    titleAppear();
    serviceAppear();

    /* Top Nav Fixed */
	const headerFixed = () => {
		var headerJS = document.getElementById('headerContainer');
		installMediaQueryWatcher("(min-width: 576px)", function(matches) {
			if (matches) {
				ScrollTrigger.create({
					trigger: body,
					start: () => 'top+=105px top',
					end: () => 'top+=105px top',
					//markers: true,

					onEnter: function() {
						//console.log('yes,wein');
						headerJS.classList.add("--tiny");
					},
					onEnterBack: function() {
						//console.log('no,weout');
						headerJS.classList.remove("--tiny");
					},
				})  
			} else {
				headerJS.classList.add("--tiny");

				ScrollTrigger.create({
					trigger: body,
					start: () => 'top+=75px top',
					end: () => 'top+=75px top',
					//markers: true,

					onEnter: function() {
						//console.log('yes,wein');
						headerJS.classList.add("--border");
					},
					onEnterBack: function() {
						//console.log('no,weout');
						headerJS.classList.remove("--border");
					},
				})  
			}
		});
	}
	headerFixed();	

    /* Project full - Swiper */
    const swipersFunction = () => {
      var swiperInterfaceDesktop = document.getElementsByClassName("interface-swiper-desktop");
      if (typeof(swiperInterfaceDesktop) != 'undefined' && swiperInterfaceDesktop != null) {
          var interfaceSwiperDesktop = new Swiper('.interface-swiper-desktop', {
            slidesPerView: 'auto',
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            spaceBetween: 35,
            breakpoints: {
              576: {
                spaceBetween: 75,
              },
              1200: {
                spaceBetween: 80,
              },
          },
      
            //init: false,
    
            pagination: {
              el: '.swiper-pagination-desktop',
              clickable: true,
          },
        });
      }

      var swiperInterfaceDesktopLarge = document.getElementsByClassName("interface-swiper-desktop-large");
      if (typeof(swiperInterfaceDesktopLarge) != 'undefined' && swiperInterfaceDesktopLarge != null) {
          var interfaceSwiperDesktopLarge = new Swiper('.interface-swiper-desktop-large', {
            slidesPerView: 'auto',
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            spaceBetween: 35,

            breakpoints: {
                576: {
                  spaceBetween: 75,
                },
                1200: {
                  spaceBetween: 80,
                },
            },
      
            //init: false,
    
            pagination: {
              el: '.swiper-pagination-desktop',
              clickable: true,
          },
        });
      }

      var swiperInterfaceResponsive = document.getElementsByClassName("interface-swiper-responsive");
      if (typeof(swiperInterfaceResponsive) != 'undefined' && swiperInterfaceResponsive != null) {
          var interfaceSwiperResponsive = new Swiper('.interface-swiper-responsive', {
            slidesPerView: 4,
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            spaceBetween: 100,
            //init: false,
    
            pagination: {
              el: '.swiper-pagination-responsive',
              clickable: true,
          },
        });
      }

      var swiperInterfaceResponsiveMobile = document.getElementsByClassName("interface-swiper-responsive-mobile");
      
      if (typeof(swiperInterfaceResponsiveMobile) != 'undefined' && swiperInterfaceResponsiveMobile != null) {
          installMediaQueryWatcher("(max-width: 991px)", function(matches) {
              if (matches) {
                  var interfaceSwiperResponsiveMobil = new Swiper('.interface-swiper-responsive-mobile', {
                      slidesPerView: 1,
                      loop: true,
                      grabCursor: true,
                      centeredSlides: true,
                      spaceBetween: 35,
                      //init: false,

                      breakpoints: {
                          // when window width is >= 576px
                          576: {
                              slidesPerView: 'auto',
                              spaceBetween: 50,
                          },
                          768: {
                              slidesPerView: 'auto',
                              spaceBetween: 75,
                          },
                      },
            
                      /*
                      freeMode: {
                        enabled: true,
                        minimumVelocity: 0.15,
                      },
                      */
              
                      pagination: {
                        el: '.swiper-pagination-responsive-mobile',
                        clickable: true,
                    },
                  });
              }
          });
      }
    }
    swipersFunction();

    /* Test link for no force reload */
    function testForceReload() {
		var links = document.querySelectorAll('a[href]');
		var cbk = function(e) {
        	if(e.currentTarget.href === window.location.href) {
        		e.preventDefault();
        		e.stopPropagation();
      		}
    	};

		for(var i = 0; i < links.length; i++) {
			links[i].addEventListener('click', cbk);
		}
  	}
  	testForceReload();

	/* Update nav active */
  	function updateMenu(url) {  
		const headerActive = document.querySelector('.nav a.is-active');
	
		if (headerActive !== null) {
			headerActive.classList.remove('is-active');
		}
			
		const links = Array.from(document.querySelectorAll('#menu .nav a'));
		links.push("http://localhost:9000");
	
		//for (var i=0; i<links.length; i++)
		//console.log(i + ". " + links[i]);
			
		const index = links.map(link => link.href).findIndex((href) => {
			return url.indexOf(href) !== -1;
		});
	
		if (index !== -1) {
			links[index].classList.add('is-active');
		}

		const linkBranding = document.getElementById('branding');

		if(window.location.href.indexOf(linkBranding.href) !== -1) {
			//linkBranding.classList.add('is-active');
		} else {
			//linkBranding.classList.remove('is-active');
		}
	}

	/* Test link from other space */
	function linkFirstLoad() {
		//console.log(window.location.href);

		const links = Array.from(document.querySelectorAll('#menu .nav a'));
		//links.push("http://localhost:9000");

		links.forEach(function(link){
			var linkHref = link.href;
			if(window.location.href.indexOf(link.href) !== -1) {
				link.classList.add('is-active');
			}
		});
	}
	linkFirstLoad();
	



    ///// AJAX //

	barba.hooks.before((data) => {
		updateMenu(data.trigger.href);
	});

	barba.hooks.afterEnter((data) => {
		//console.log('afterHooks');

		const cleanGSAP = () => {
			let existingScrollTriggers = ScrollTrigger.getAll();
			for (let index = 0; index < existingScrollTriggers.length; index++) {
				const singleTrigger = existingScrollTriggers[index];
				singleTrigger.kill(false);
			}
			ScrollTrigger.refresh();
			window.dispatchEvent(new Event("resize"));

			headerFixed();
			serviceAppear();
			teaserAppear();
		};

		cleanGSAP();

	});

	const transitionBackground = document.querySelector('.transition-background');

	barba.init({
		preventRunning: true,

		views: [{
			namespace: 'services',
			afterLeave(data) {
				body.setAttribute("id", "wrapper--other");
			},
			
			/* PROB OVER HERE */
			beforeEnter(data) {
				body.setAttribute("id", "wrapper--services");
			}	
		}],

		transitions: [{
			name: 'main-transition',
			beforeLeave(data) {
				gsap.fromTo(transitionBackground, {
					y: "100%",
					//skewX:15,
				}, {
					y: 0, 
					//skewX:0,
					duration: 0.75,
					ease: 'power4.inOut'
				});
				

				if (body.classList.contains('menu-open')) {
					body.classList.remove("menu-open");
				}
			},
			
			leave(data) {
				document.getElementById('headerContainer').classList.remove("--border");
			},
		
			enter(data) {		
				window.scrollTo(0, 0);
				
				gsap.to('.main-header', {
					y: 0,
					duration: 0,
					opacity: 0,
				});
				
				gsap.to(transitionBackground, {
					y: "-100%", 
					duration: 0.75,
				});
			},

			afterEnter(data) {
				testForceReload();
				titleAppear();
				swipersFunction();
			}
		}]
	});
});